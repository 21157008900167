import { Checkbox, Col, Form, Input, InputNumber, Row, Tooltip } from 'antd';
import React from 'react';
import {
    checkCaculateData,
    checkCallendarEffortData,
    checkConditionData,
    checkTimesData
} from '../../utils/ValidateSyncRule';
import { QuestionCircleOutlined } from '@ant-design/icons';

function UpdateTemplateExcel({ form, sampleData }) {
    const isMonthlyIncome = Form.useWatch('IsMonthlyIncome', form);

    const validateCondition = (_, value) => {
        let result = checkConditionData(value, sampleData);
        if (result.checkCondition) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.ConditionError));
    };
    const validateTimes = (_, value) => {
        let result = checkTimesData(value, sampleData);
        if (result.checkTimes) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.TimesError));
    };
    const validateCallendarEffort = (_, value) => {
        let result = checkCallendarEffortData(value, sampleData);
        if (result.checkCalEffoft) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.CalEffoftError));
    };

    const validateCaculationFormula = (_, value) => {
        let result = checkCaculateData(value, sampleData);
        if (result.checkCaculation) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.CaculationError));
    };
    const onChangeChkMonthlyIncome = (value) => {
        form.setFieldsValue({ CalendarEffort: '' });
    };

    return (
        <>
            <Row gutter={16} align="middle">
                <Col span={12}>
                    <Form.Item
                        name="AccountRow"
                        label={
                            <span>
                                Account Column &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="The column in the Excel file that you have selected is 'account'"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ required: true, message: 'Please input account column' }]}
                    >
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter account column" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="ProjectCodeRow" label="Project Code Column">
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter project code column" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} align="middle">
                <Col span={12}>
                    <Form.Item name="NoteRow" label="Note Column">
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter note column" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="Times" label="Times Column" rules={[{ validator: validateTimes }]}>
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter times column" min={1} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} align="middle">
                <Col span={12}>
                    <Form.Item
                        name="Condition"
                        label={
                            <span>
                                Condition &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="Conditions for request points to be satisfied:&#10;column: [column number]&#10;equality '=='&#10;/inequality '!='&#10;can only compare columns with the same value type&#10;Example: [x]=='Approved'"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ validator: validateCondition }]}
                    >
                        <Input style={{ width: '100%' }} type="text" className="me-2" placeholder="Enter condition" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="CaculationFormula"
                        label={
                            <span>
                                Caculation Formula &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="Scoring formula for request points:&#10;only numbers can be calculated with the Number and Percentage columns&#10;Example: [x]*5*[y]"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ validator: validateCaculationFormula }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            type="text"
                            className="me-2"
                            placeholder="Enter caculation formula"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item valuePropName="checked" name="IsMonthlyIncome" style={{ marginBottom: 8 }}>
                        <Checkbox onChange={onChangeChkMonthlyIncome} checked={isMonthlyIncome} id="checkbox">
                            Monthly Income
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {isMonthlyIncome && (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="CalendarEffort"
                            label={<span>Calendar Effort Column</span>}
                            rules={
                                isMonthlyIncome
                                    ? [
                                          { required: true, message: 'Calendar Effort Column is required' },
                                          { validator: validateCallendarEffort }
                                      ]
                                    : []
                            }
                        >
                            <Input
                                style={{ width: '100%' }}
                                type="text"
                                className="me-2"
                                placeholder="Enter calendar effort column"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </>
    );
}

export default UpdateTemplateExcel;
