import axios from 'axios';
import { Server } from '../dataConfig';

export const getCategoryByDepartment = async (token, DepartmentID) => {
    try {
        const res = await axios.get(`${Server}/category?DepartmentID=${DepartmentID}`, token);
        return res.data.data;
    } catch (err) {
        return null;
    }
};
