import { Button, Modal, Row, Space, Table, Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useRefreshToken from '../../Hook/useRefreshToken';
import { getAllHistoryBadge } from '../../services/UsermasterAPI';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getExcelBadge, getFileDownLoadBadgeHistory } from '../../services/ExportAPI';
import { uploadBadgeExcel } from '../../services/ImportAPI';
import { rollBackMedals } from '../../services/BadgeAPI';
import ImportFile from '../ImportFile';
import { PAGE_INDEX, PAGE_SIZE, PAGE_SIZE_OPTIONS_TABLE } from '../../constants/pagination';
import { useContext } from 'react';
import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';

const { confirm } = Modal;

function AwardManualHistory() {
    const { DepartmentID } = useSelector((a) => a.DepartmentSettingSlice);
    const [dataHistory, setDataHistory] = useState([]);
    const [pageModalAwardManual, setPageModalAwardManual] = useState(PAGE_INDEX);
    const [pageSizeModalAwardManual, setPageSizeModalAwardManual] = useState(PAGE_SIZE);
    const [modalImportBadge, setModalImportBadge] = useState(false);
    const [dataBadgeHistory, setRefreshDataBadgeHistory] = useRefreshToken(getAllHistoryBadge);
    const { getToken, getTokenFormData, getTokenDownload } = useContext(GetTokenV2Context);

    useEffect(() => {
        setDataHistory(dataBadgeHistory);
    }, [dataBadgeHistory]);

    const downloadExcel = (URL, record) => {
        return (
            <Button
                type="primary"
                onClick={debounce(() => {
                    handleDownload(URL, record);
                }, 500)}
            >
                Download
            </Button>
        );
    };

    const handleDownload = (URL, record) => {
        let exportFileName;
        let syncDownLoadType;
        if (URL === record.FileSuccessURL) {
            exportFileName = `MEDAL-IMPORT-FILE-SUCCESS-${record.ID}-${moment(new Date()).format('YYYY_MM_DD_HH_mm')}`;
            syncDownLoadType = 1;
        }
        if (URL === record.FileFailURL) {
            exportFileName = `MEDAL-IMPORT-FILE-FAIL-${record.ID}-${moment(new Date()).format('YYYY_MM_DD_HH_mm')}`;
            syncDownLoadType = 2;
        }
        getTokenDownload(getFileDownLoadBadgeHistory, exportFileName, record.ID, syncDownLoadType);
    };

    const callbackSuccess = () => {
        setRefreshDataBadgeHistory(new Date());
    };

    const handleRollBack = (record) => {
        getToken(rollBackMedals, 'Rollback successfully', callbackSuccess, null, {}, record.ID);
    };

    const showCallBackConfirm = (functionOK) => {
        confirm({
            title: `Do you want to roll back ?`,
            icon: <ExclamationCircleFilled />,
            content: null,
            centered: true,
            okType: 'danger',
            className: 'custom-confirm-styles',
            onOk: functionOK
        });
    };

    const rollback = (URL, record) =>
        Boolean(record.hasRollback) && (
            <Button
                type="primary"
                onClick={() =>
                    showCallBackConfirm(
                        debounce(() => {
                            handleRollBack(record);
                        }, 500)
                    )
                }
            >
                RollBack
            </Button>
        );

    const modalAwardManualColums = [
        {
            title: 'Total Line',
            dataIndex: 'TotalLine',
            key: 'TotalLine'
        },
        {
            title: 'Number Line Fail',
            dataIndex: 'NumberLineFail',
            key: 'NumberLineFail'
        },
        {
            title: 'Number Line Success',
            dataIndex: 'NumberLineSuccess',
            key: 'NumberLineSuccess'
        },
        {
            title: 'File Fail',
            dataIndex: 'FileFailURL',
            key: 'FileFailURL',
            render: downloadExcel
        },
        {
            title: 'File Success',
            dataIndex: 'FileSuccessURL',
            key: 'FileSuccessURL',
            render: downloadExcel
        },
        {
            title: 'Rollback',
            dataIndex: 'Rollback',
            key: 'Rollback',
            render: rollback
        },
        {
            title: 'Created By',
            dataIndex: 'CreatedBy',
            key: 'CreatedBy'
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedDate',
            key: 'CreatedDate'
        }
    ];

    const onModalAwardManualTableChange = (pagination, filters, sorter, extra) => {
        setPageModalAwardManual(pagination.current);
        setPageSizeModalAwardManual(pagination.pageSize);
    };

    const handleBadgeExport = () => {
        const exportFileName = `MEDAL-TEMPLATE_${moment(new Date()).format('YYYY_MM_DD_HH_mm')}`;
        getTokenDownload(getExcelBadge, exportFileName, DepartmentID);
    };

    const handleImport = (file) => {
        setModalImportBadge(false);

        const formData = new FormData();
        formData.append('file', file);

        getTokenFormData(uploadBadgeExcel, 'Import success', callbackSuccess, null, formData, DepartmentID);
    };

    return (
        <>
            <Row justify="end">
                <Space style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={debounce(handleBadgeExport, 500)}>
                        Export
                    </Button>
                    <Button type="primary" onClick={() => setModalImportBadge(true)}>
                        Import
                    </Button>
                </Space>
            </Row>
            <Table
                bordered
                style={{ borderRadius: 0 }}
                rowKey={(record) => record.ID}
                columns={modalAwardManualColums}
                onChange={onModalAwardManualTableChange}
                dataSource={dataHistory ? dataHistory : []}
                pagination={{
                    total: dataHistory ? dataHistory.length : 0,
                    current: pageModalAwardManual,
                    pageSize: pageSizeModalAwardManual,
                    showSizeChanger: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS_TABLE,
                    showTotal: (total) => `Total ${total} items`
                }}
            />
            {/* Modal Import Badge */}
            <Modal
                zIndex={1}
                title="Import Medal"
                centered
                open={modalImportBadge}
                footer={null}
                width={500}
                onCancel={() => setModalImportBadge(false)}
            >
                <ImportFile onFinish={handleImport} />
            </Modal>
        </>
    );
}

export default AwardManualHistory;
