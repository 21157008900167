import { useState } from 'react';
import useRefreshToken from '../../Hook/useRefreshToken';
import { PAGE_INDEX, PAGE_SIZE, PAGE_SIZE_OPTIONS_TABLE } from '../../constants/pagination';
import { getRequest } from '../../services/RequestAPI';
import { useSelector } from 'react-redux';
import { Button, Col, Grid, Modal, Row, Space, Table } from 'antd';
import SearchInput from '../SearchInput';
import { useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { deleteRewardMedalHistory, getRewardMedalHistoryAll } from '../../services/BadgeAPI';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { useContext } from 'react';

const { useBreakpoint } = Grid;
const { confirm } = Modal;

function AwardAutoHistory() {
    const { xs } = useBreakpoint();
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [rewardData, setRefresh] = useRefreshToken(getRewardMedalHistoryAll, searchQuery);
    const { getToken } = useContext(GetTokenV2Context);

    useEffect(() => {
        setData(rewardData);
    }, [rewardData]);

    const columns = [
        {
            title: 'Account',
            dataIndex: 'Account',
            key: 'Account',
            align: 'center',
            width: 150
        },
        {
            title: 'Group',
            dataIndex: 'Group',
            key: 'Group',
            align: 'center',
            width: 200
        },
        {
            title: 'Medal',
            dataIndex: 'Medal',
            key: 'Medal',
            align: 'center'
        },
        {
            title: 'Level',
            dataIndex: 'Level',
            key: 'Level',
            align: 'center',
            width: 100
        },
        {
            title: 'Created Date',
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            align: 'center',
            width: 150
        }
    ];

    const handleAfterChangeSearch = (value) => {
        setSearchQuery(value);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const onTableChange = (pagination, filters, sorter, extra) => {
        setPage(pagination.current);
        setPageSize(pagination.pageSize);
    };
    const onDeleteReward = () => {
        confirm({
            title: `Are you sure delete reward?`,
            icon: <ExclamationCircleFilled />,
            content: null,
            centered: true,
            okType: 'danger',
            className: 'custom-confirm-styles',
            onOk() {
                handleDeleteReward();
            }
        });
    };

    const handleDeleteReward = () => {
        const body = {
            UserBadgeID: selectedRowKeys
        };
        getToken(deleteRewardMedalHistory, 'Reward delete successfully!', callbackSuccess, null, body);
    };

    const callbackSuccess = () => {
        setRefresh(new Date());
    };

    return (
        <>
            <Row>
                <Col span={12}>
                    <Row className="mb-3" hidden={selectedRowKeys.length === 0}>
                        <Space>
                            <Button type="primary" shape="round" size="middle" onClick={onDeleteReward}>
                                Delete reward
                            </Button>
                        </Space>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row justify="end" className="mb-3">
                        <SearchInput afterChange={handleAfterChangeSearch} style={{ width: xs ? '100%' : 250 }} />
                    </Row>
                </Col>
            </Row>
            <Table
                bordered
                style={{ borderRadius: 0 }}
                rowKey={(record) => record.ID}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange
                }}
                columns={columns}
                dataSource={data ? data : []}
                onChange={onTableChange}
                pagination={{
                    total: data ? data.totalItems : 0,
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS_TABLE,
                    showTotal: (total) => `Total ${total} items`
                }}
            />
        </>
    );
}

export default AwardAutoHistory;
