import { Button, Card, Input, Modal, Row, Select, Space, Tabs, Tag, Typography } from 'antd';
import SellProduct from '../../components/User/SellProduct';
import PublicShop from '../../components/User/PublicShop';
import MyShop from '../../components/User/MyShop';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getOneUserMasterByAccount } from '../../services/UsermasterAPI';
import { useSelector } from 'react-redux';
import useRefreshToken from '../../Hook/useRefreshToken';
import BreadcrumbApp from '../../components/BreadcrumbApp';
import SearchInput from '../../components/SearchInput';
import { setStatusLoading } from '../../store/LoadingSlice';
import { useDispatch } from 'react-redux';
import { getCategoryByDepartment } from '../../services/CategoryAPI';
const SellButton = styled(Button)`
     {
        padding: '0.75rem 2.25rem';
        width: 135px;
    }
`;

function Shop() {
    const dispatch = useDispatch();
    const [openModalSellProduct, setModalSellProduct] = useState();
    const [keyword, setKeyword] = useState('');
    const [categorySearch, setCategorySearch] = useState([]);
    const [categorySelected, setCategorySelected] = useState([]);
    const [isOpenDrpSelect, setIsOpenDrpSelect] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [tabKey, setTabKey] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const { account } = useSelector((state) => state.UserSlice);
    const { CoinName } = useSelector((state) => state.DepartmentSettingSlice);

    const [userData] = useRefreshToken(getOneUserMasterByAccount, account);
    const [categoryData] = useRefreshToken(getCategoryByDepartment);

    const shopTabItems = [
        {
            label: `Shop`,
            key: 1,
            children: (
                <PublicShop
                    refresh={tabKey === 1 && refresh}
                    searchQuery={keyword}
                    category={categorySearch}
                    categoryList={categoryList}
                    tabKey={tabKey}
                />
            )
        },
        {
            label: `My Shop`,
            key: 2,
            children: (
                <MyShop
                    refresh={tabKey === 2 && refresh}
                    searchQuery={keyword}
                    category={categorySearch}
                    categoryList={categoryList}
                    tabKey={tabKey}
                    userData={userData}
                />
            )
        }
    ];

    useEffect(() => {
        refresh && setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        if (userData) {
            dispatch(setStatusLoading(false));
        }
    }, [userData]);

    useEffect(() => {
        if (categoryData) {
            setCategoryList(categoryData.map((item) => ({ label: item.Name, value: item.ID })));
        }
    }, [categoryData]);

    const handleAfterChangeSearch = (value) => {
        setKeyword(value);
    };

    // handle tab click
    const onTabClickHandle = (key, event) => {
        setTabKey(key);
        setKeyword('');
    };

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={'#735ad8'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    const selectProps = {
        mode: 'multiple',
        style: {
            width: 350
        },
        value: categorySelected,
        options: categoryList,
        tagRender,
        onChange: (value) => {
            setCategorySelected(value);
            if (!isOpenDrpSelect) {
                setCategorySearch(value && value.length > 0 ? value : null);
            }
        },
        onDropdownVisibleChange: (open) => {
            setIsOpenDrpSelect(open);
        },
        onBlur: () => {
            setCategorySearch(categorySelected && categorySelected.length > 0 ? categorySelected : null);
        },
        placeholder: 'Select categories',
        maxTagCount: 'responsive'
    };

    const operations = (
        <Space>
            <Select {...selectProps} />
            <SearchInput onClear={tabKey} afterChange={handleAfterChangeSearch} />
        </Space>
    );

    return (
        <div style={{ margin: '1rem 2.5rem' }}>
            {/* Breadcrumb */}
            {/* <BreadcrumbApp /> */}

            <Card className="card-unstyle" bordered={false} style={{ marginBottom: '1.25rem' }}>
                <Row align="middle" justify="space-between">
                    <Typography.Title style={{ lineHeight: 1.1 }} level={3}>
                        Shop
                    </Typography.Title>
                    <Space size="large">
                        <Typography.Text style={{ fontSize: 16 }}>
                            You currently have&nbsp;
                            <Typography.Text style={{ fontWeight: 700, fontSize: 18 }}>
                                {userData ? userData.TotalCoin : ''} {CoinName}
                            </Typography.Text>
                        </Typography.Text>
                        <SellButton size="large" type="primary" onClick={() => setModalSellProduct(true)}>
                            Sell Product
                        </SellButton>
                    </Space>
                </Row>
            </Card>

            {/* Tab list */}
            <Tabs
                type="card"
                items={shopTabItems}
                tabBarExtraContent={operations}
                destroyInactiveTabPane={true}
                onTabClick={onTabClickHandle}
            />

            {/* Modal sell product */}
            <Modal
                title="Sell Product"
                centered
                width={1000}
                open={openModalSellProduct}
                onCancel={() => setModalSellProduct(false)}
                footer={[]}
                destroyOnClose={true}
            >
                <SellProduct
                    setRefresh={setRefresh}
                    userData={userData}
                    setModalSellProduct={setModalSellProduct}
                    categoryList={categoryList}
                />
            </Modal>
        </div>
    );
}

export default Shop;
