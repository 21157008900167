import React from 'react';

import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from './authConfig';
import App from './App.jsx';
import './i18n/i18n';

import 'bootstrap/dist/css/bootstrap.min.css';

export const msalInstance = new PublicClientApplication(msalConfig);

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');

const root = createRoot(container);

root.render(<App instance={msalInstance} />);
