import { Button, Form, Row, DatePicker, theme } from 'antd';
import debounce from 'lodash/debounce';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { ClearCoin } from '../../services/WalletAPI';
import { getYearList } from '../../services/LeaderBoardAPI';
import useRefreshToken from '../../Hook/useRefreshToken';

function ClearCoinForm({ setModalClearCoin }) {
    const {
        token: { colorPrimary }
    } = theme.useToken();
    const { RangePicker } = DatePicker;

    const [form] = Form.useForm();
    const { DepartmentID } = useSelector((a) => a.DepartmentSettingSlice);
    const { getToken, getTokenPromise } = useContext(GetTokenV2Context);

    const callbackFinish = () => {
        setModalClearCoin(false);
    };

    const callbackFailed = () => {
        setModalClearCoin(true);
    };

    const handleFinish = (values) => {
        const [startDate, endDate] = values.SelectedTime;
        const startMonth = startDate.month() + 1; // Adding 1 to adjust for zero-based months
        const startYear = startDate.year();
        const endMonth = endDate.month() + 1;
        const endYear = endDate.year();

        const body = {
            startMonth,
            startYear,
            endMonth,
            endYear
        };

        getToken(ClearCoin, 'Clear coin success', callbackFinish, callbackFailed, body, DepartmentID);
    };

    return (
        <Form style={{ marginTop: '2rem' }} form={form} layout="vertical" name="form_in_modal" onFinish={handleFinish}>
            <Form.Item
                name="SelectedTime"
                label="Select Time"
                rules={[{ required: true, message: 'Please choose Time!' }]}
            >
                <RangePicker picker="month" style={{ width: '100%' }} />
            </Form.Item>
            <Row justify="end">
                <Button size="large" type="primary" onClick={debounce(() => form.submit(), 500)} style={{ width: 150 }}>
                    Clear Coin
                </Button>
            </Row>
        </Form>
    );
}

export default ClearCoinForm;
