import './style.scss';
export const formConfigUserOnlySee = [
    {
        type: 'input',
        name: 'DisplayName',
        label: 'Name',
        placeholder: 'Enter your name',
        span: 10,
        offset: 1,
        readOnly: true
    },
    {
        type: 'input',
        name: 'Email',
        label: 'Email',
        placeholder: 'Enter your email address',
        span: 10,
        offset: 1,
        readOnly: true
    },
    {
        type: 'input',
        name: 'Account',
        label: 'Account',
        placeholder: 'Enter your account',
        span: 10,
        offset: 1,
        readOnly: true
    },
    {
        type: 'hidden',
        name: '',
        label: '',
        placeholder: '',
        span: 12
    }
];

// export const validateMessage = [

// ]
