const REQUEST_STATUS = {
    APPROVED: 'Approved',
    WAIT_PM: 'Waiting PM Confirm',
    WAIT_HEAD: 'Waiting Head Approve',
    CANCEL: 'Canceled',
    REJECT: 'Rejected'
};

const PROJECT_STATUS = {
    ON_GOING: 'On-going',
    CLOSED: 'Closed',
    CANCELLED: 'Cancelled',
    TENTATIVE: 'Tentative',
    WAITING: 'Waiting'
};

export { REQUEST_STATUS, PROJECT_STATUS };
