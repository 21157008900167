import { Button, Col, Form, Input, Row, Select, Tag } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import useRefreshToken from '../../Hook/useRefreshToken';
import { createUserMasterGroupChild, getAllUserDepartment, getUserGroupChild } from '../../services/GroupChildAPI';
import { setStatusLoading } from '../../store/LoadingSlice';
import { useDispatch } from 'react-redux';
function SubDepartmentSetting({ departmentCode, setRefreshSubDepa, setOpenModalSubDepa }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { getToken } = useContext(GetTokenV2Context);
    const { DepartmentID } = useSelector((a) => a.DepartmentSettingSlice);
    const [users] = useRefreshToken(getAllUserDepartment);
    const [groupData] = useRefreshToken(getUserGroupChild, departmentCode);
    const [leaders, setLeaders] = useState([]);
    const tagRender = (props) => {
        const { label, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={'#735ad8'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };

    useEffect(() => {
        if (groupData && users) {
            setLeaders(users.map((user) => ({ ...user, value: user.ID })));
            form.setFieldsValue({
                Code: departmentCode,
                UserMasterID: groupData && groupData.length > 0 ? groupData.map((item) => item.ID) : []
            });
            dispatch(setStatusLoading(false));
        }
    }, [groupData, users]);

    const callbackSuccess = () => {
        setOpenModalSubDepa(false);
        setRefreshSubDepa(new Date());
    };

    const handleFinish = (values) => {
        let body = values;
        getToken(createUserMasterGroupChild, 'Update success', callbackSuccess, null, body, DepartmentID);
    };

    return (
        <Form
            form={form}
            labelCol={{ span: 8 }}
            labelWrap
            labelAlign="left"
            style={{ marginTop: '1.5rem' }}
            onFinish={handleFinish}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Department Code"
                        name="Code"
                        rules={[{ required: true, message: 'Department Code is required' }]}
                    >
                        <Input disabled type="text" min={5} max={30} placeholder="Department Code" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Leader" name="UserMasterID">
                        <Select
                            mode="multiple"
                            tagRender={tagRender}
                            placeholder={'Select categories'}
                            allowClear
                            options={leaders}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item hidden name="DepartmentID" initialValue={DepartmentID}>
                <Input type="hidden" />
            </Form.Item>

            <Row justify="end" align="middle">
                <Button size="large" type="primary" htmlType="submit">
                    Submit
                </Button>
            </Row>
        </Form>
    );
}

export default SubDepartmentSetting;
