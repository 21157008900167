import axios from 'axios';
import { Server } from '../dataConfig';

export const logAuthEvent = async (token, body) => {
    try {
        await axios.post(`${Server}/authentication/log-event`, body, token);
    } catch (err) {
        throw err.response.data.error;
    }
};
