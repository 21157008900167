import { useParams } from 'react-router-dom';
import useRefreshToken from '../../../Hook/useRefreshToken';
import { getSetting } from '../../../services/SettingAPI';
import { getOneDepartmentDetail } from '../../../services/DepartmentAPI';

import { getAllUserMasterNoPageWithDepartmentID } from '../../../services/UsermasterAPI';
import Loading from '../../sharedPage/pages/Loading';
import DepartmentDetail from '../components/detail/DepartmentDetail';
import { getDefaultHead } from '../../../services/DefaultHeadAPI';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusLoading } from '../../../store/LoadingSlice';

const DepartmentDetailPage = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((a) => a.LoadingSlice);

    const { departmentID } = useParams();

    const [depaSetting, setRefresh3] = useRefreshToken(getSetting, departmentID);
    const [defaultHead] = useRefreshToken(getDefaultHead, departmentID);

    const [allBU, setRefresh] = useRefreshToken(getAllUserMasterNoPageWithDepartmentID, 2, departmentID);

    const [depaDetail, setRefresh2] = useRefreshToken(getOneDepartmentDetail, departmentID);

    const resfreshAll = () => {
        setRefresh(new Date());
        setRefresh2(new Date());
        setRefresh3(new Date());
    };
    useEffect(() => {
        if (depaSetting && defaultHead && allBU && depaDetail) {
            dispatch(setStatusLoading(false));
        }
    }, [depaSetting, defaultHead, allBU, depaDetail]);

    return depaSetting === null || allBU === null || depaDetail === null || defaultHead === null ? (
        <Loading />
    ) : (
        <Spin spinning={isLoading} size="large" style={{ maxHeight: '100%', zIndex: 1001 }}>
            <DepartmentDetail
                defaultHead={defaultHead}
                depaSetting={depaSetting}
                BUList={allBU}
                depaDetail={depaDetail}
                setRefresh={resfreshAll}
            />
        </Spin>
    );
};

export default DepartmentDetailPage;
