import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { UserMaster } from '../../dataConfig';
import { createUserMaster, getOneUserMasterByAccount, updateUserInfo } from '../../services/UsermasterAPI';
import { setStatusLoading } from '../../store/LoadingSlice';
import { notBeEmpty, validateVNPhone } from '../../utils/validator';
import useRefreshToken from '../../Hook/useRefreshToken';
import { getAllDepartmentGroupChild } from '../../services/GroupChildAPI';
const dateFormat = 'DD/MM/YYYY';

const MEMBER_STATUS_CODE = {
    ACTIVE: 1,
    INACTIVE: 2,
    UnPaidLeave: 3,
    NOT_RANKING: 4,
    Maternity: 5
};
const MEMBER_STATUS_NAME = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    UnPaidLeave: 'Un-paid leave',
    NOT_RANKING: 'Not ranking',
    Maternity: 'Maternity'
};
const MEMBER_TYPE = {
    SVTT: 'SVTT',
    NVCT: 'NVCT'
};
const statusOptions = [
    { value: MEMBER_STATUS_CODE.ACTIVE, label: MEMBER_STATUS_NAME.ACTIVE },
    { value: MEMBER_STATUS_CODE.INACTIVE, label: MEMBER_STATUS_NAME.INACTIVE },
    { value: MEMBER_STATUS_CODE.UnPaidLeave, label: MEMBER_STATUS_NAME.UnPaidLeave },
    { value: MEMBER_STATUS_CODE.NOT_RANKING, label: MEMBER_STATUS_NAME.NOT_RANKING },
    { value: MEMBER_STATUS_CODE.Maternity, label: MEMBER_STATUS_NAME.Maternity }
];
const roleOptions = [
    {
        label: 'HEAD',
        value: 2
    },
    {
        label: 'PM',
        value: 3
    },
    {
        label: 'MEMBER',
        value: 4
    }
];
const ContractTypeOptions = [
    {
        label: MEMBER_TYPE.NVCT,
        value: 1
    },
    {
        label: MEMBER_TYPE.SVTT,
        value: 2
    }
];

function CreateMember({ account, setRefresh, setModalState }) {
    const dispatch = useDispatch();
    const { DepartmentID, Code } = useSelector((state) => state.DepartmentSettingSlice);
    const initRule = {
        Account: '',
        Status: MEMBER_STATUS_CODE.ACTIVE,
        PhoneNumber: '',
        DepartmentID: Code,
        TotalCoin: 0,
        TotalPoint: 0
    };
    const [form] = Form.useForm();
    const { getToken, getTokenPromise } = useContext(GetTokenV2Context);
    const [group] = useRefreshToken(getAllDepartmentGroupChild, DepartmentID);

    useEffect(() => {
        form.setFieldsValue(initRule);
        account &&
            getTokenPromise(getOneUserMasterByAccount, DepartmentID, account).then((res) => {
                if (res) {
                    let userDetail = {
                        ...res
                    };
                    if (res.DOB) {
                        const dob = dayjs(moment(res.DOB).format(dateFormat), dateFormat);
                        userDetail.DOB = dob;
                    } else {
                        delete userDetail['DOB'];
                    }
                    if (res.DateJoinUnit) {
                        const dateJoinUnit = dayjs(moment(res.DateJoinUnit).format(dateFormat), dateFormat);
                        userDetail.DateJoinUnit = dateJoinUnit;
                    } else {
                        delete userDetail['DateJoinUnit'];
                    }
                    form.setFieldsValue(userDetail);
                    dispatch(setStatusLoading(false));
                }
            });
    }, [account]);

    const callbackSuccess = () => {
        setRefresh(new Date());
        setModalState(false);
    };

    const handleFinish = (values) => {
        const { JobTitle } = values;
        let body = {
            ...values,
            DepartmentID
        };
        if (values.Note) {
            body.Note = values.Note.trim();
        }
        body.DisplayName = values.DisplayName.trim();
        body.Account = values.Account.trim();
        body.JobTitle = JobTitle ? JobTitle : '';
        getToken(
            account ? updateUserInfo : createUserMaster,
            account ? 'user has been updated' : 'New user has been created',
            callbackSuccess,
            null,
            body,
            DepartmentID,
            account ?? account
        );
    };

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 7 }}
                labelWrap
                labelAlign="left"
                style={{ marginTop: '1.5rem' }}
                onFinish={debounce(handleFinish, 300)}
            >
                <Row gutter={48}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Full Name"
                            name="DisplayName"
                            rules={[{ required: true, message: 'Full Name is required' }, { validator: notBeEmpty }]}
                        >
                            <Input placeholder="Enter Full Name" />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} md={12}>
                        <Form.Item
                            className="mb-0"
                            label="Department"
                            name="DepartmentID"
                            rules={[{ required: true, message: 'Department is required' }]}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col> */}

                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Account"
                            name="Account"
                            rules={[{ required: true, message: 'Account is required' }, { validator: notBeEmpty }]}
                        >
                            <Input placeholder="Enter account" disabled={account ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Email"
                            name="Email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'The input is not valid E-mail!'
                                }
                            ]}
                        >
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="EmployeeID"
                            name="EmployeeID"
                            rules={[{ required: true, message: 'EmployeeID is required' }]}
                        >
                            <Input placeholder="Enter EmployeeID" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Skill"
                            name="Skill"
                            // rules={[{ required: true, message: 'Skill is required' }]}
                        >
                            <Input placeholder="Enter Skill" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Birthday"
                            name="DOB"
                            // rules={[{ type: 'object', required: true, message: 'Birthday is required' }]}
                        >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Phone number"
                            name="PhoneNumber"
                            rules={[
                                { required: true, message: 'Phone number is required' },
                                { validator: validateVNPhone }
                            ]}
                        >
                            <Input placeholder="Enter Phone number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Contract type"
                            name="ContractType"
                            rules={[{ required: true, message: 'Contract type is required' }]}
                        >
                            <Select placeholder="Choice contract type" options={ContractTypeOptions} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="Note" name="Note">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Job title"
                            name="JobTitle"
                            // rules={[{ required: true, message: 'Job title is required' }]}
                        >
                            <Select
                                placeholder="Choice job title"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={UserMaster.JobTitle.map((jt) => ({ label: jt, value: jt }))}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} md={12}>
                        <Form.Item
                            label="Date join unit"
                            name="DateJoinUnit"
                        >
                            <DatePicker format={dateFormat}/>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} md={12}>
                        <Form.Item label="Role" name="RoleID" rules={[{ required: true, message: 'Role is required' }]}>
                            <Select placeholder="Choice role" options={roleOptions} />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} md={12}>
                        <Form.Item
                            label="Total coin"
                            name="TotalCoin"
                            // rules={[{ required: true, message: 'Total coin is required' }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} readOnly />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Status"
                            name="Status"
                            rules={[{ required: true, message: 'Status is required' }]}
                        >
                            <Select options={statusOptions} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="Group" name="Group">
                            <Select options={group} />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} md={12}>
                        <Form.Item
                            label="Total point"
                            name="TotalPoint"
                            // rules={[{ required: true, message: 'Total coin is required' }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} readOnly />
                        </Form.Item>
                    </Col> */}
                </Row>
                <Row justify="end" align="middle">
                    <Button size="large" type="primary" htmlType="submit">
                        {account ? 'Update' : 'Create'}
                    </Button>
                </Row>
            </Form>
        </>
    );
}

export default CreateMember;
