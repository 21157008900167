import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useEffect } from 'react';

function SearchInput({ value, defaultValue, onClear, afterChange, ...prop }) {
    const [inputValue, setInputValue] = useState(value);

    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (!value) {
            afterChange(value);
        }
    };

    const handlePressEnter = (event) => {
        const value = event.target.value;
        afterChange(value);
    };

    useEffect(() => {
        setInputValue('');
    }, [onClear]);

    return (
        <Input
            {...prop}
            defaultValue={defaultValue ?? ''}
            value={inputValue}
            prefix={<SearchOutlined />}
            placeholder="Search"
            onPressEnter={handlePressEnter}
            onChange={handleChange}
        />
    );
}

export default SearchInput;
