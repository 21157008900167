import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRefreshToken from '../../Hook/useRefreshToken';
import LeaderboardStatistic from '../../components/User/LeaderboardStatistic';
import LeaderboardUserList from '../../components/User/LeaderboardUserList';
import { PAGE_INDEX, PAGE_SIZE } from '../../constants/pagination';
import { getLeaderboardV2Data, getYearList } from '../../services/LeaderBoardAPI';
import { setStatusLoading } from '../../store/LoadingSlice';

function LeaderBoard() {
    const dispatch = useDispatch();
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [pageIndex, setPageIndex] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [year, setYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear());
    const [month, setMonth] = useState( new Date().getMonth() === 0 ? 12 : 0);
    const [keyword, setKeyword] = useState(null);
    const [kper, setKper] = useState('');
    const [group, setGroup] = useState('');
    const { userID } = useSelector((state) => state.UserSlice);
    const { DepartmentID, PointName, Code } = useSelector((a) => a.DepartmentSettingSlice);
    const maxBadge = 4;

    const [yearList] = useRefreshToken(getYearList);
    const [data, setRefresh] = useRefreshToken(
        getLeaderboardV2Data,
        userID,
        year,
        month,
        keyword,
        pageIndex,
        pageSize,
        kper,
        group
    );
    useEffect(() => {
        if (data) {
            data.LeaderBoard = data.LeaderBoard.map((item) => ({
                ...item,
                more_slot: item.badge_names && JSON.parse(item.badge_names).length - maxBadge,
                badge_names: item.badge_names && JSON.parse(item.badge_names)
            }));

            if (data.myrank) {
                {
                    data.myrank.more_slot =
                        data.myrank.badge_names && JSON.parse(data.myrank.badge_names).length - maxBadge;
                }
            }

            setLeaderboardData(data);
            if (leaderboardData !== []) {
                dispatch(setStatusLoading(false));
            }
        }
    }, [data]);
    return (
        <>
            {leaderboardData?.LeaderBoardTop ? (
                <LeaderboardStatistic data={leaderboardData} setRefreshLeaderboard={setRefresh} pointName={PointName} />
            ) : null}

            <LeaderboardUserList
                data={leaderboardData}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
                year={year}
                setYear={setYear}
                yearList={yearList}
                month={month}
                setMonth={setMonth}
                setKeyword={setKeyword}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pointName={PointName}
                setKper={setKper}
                setGroup={setGroup}
                keyword={keyword}
                maxBadge={maxBadge}
                departmentID={DepartmentID}
                departmentName={Code}
                userID={userID}
                kper={kper}
                group={group}
            />
        </>
    );
}

export default LeaderBoard;
