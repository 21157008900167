import { Tabs } from 'antd';
import AwardAutoHistory from './AwardAutoHistory';
import AwardManualHistory from './AwardManualHistory';

function AwardHistory() {
    const reqTabItems = [
        {
            label: `Manual Reward Medal`,
            key: 1,
            children: <AwardManualHistory />
        },
        {
            label: `Auto Reward Medal`,
            key: 2,
            children: <AwardAutoHistory />
        }
    ];

    return <Tabs type="card" style={{ marginTop: 24 }} items={reqTabItems} destroyInactiveTabPane={true} />;
}

export default AwardHistory;
