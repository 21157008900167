import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, DatePicker, Select, Space, Typography } from 'antd';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import { Bar, Pie } from 'react-chartjs-2';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { getCointChart, getPointChart } from '../../services/Chart';
import formatNumber from '../../utils/formatNumber';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, ChartDataLabels);

const spaceCenterStyle = { width: '100%', display: 'flex', justifyContent: 'center' };
const mbStyle = { marginBottom: '1rem' };
const pdContainerStyle = { padding: '2rem' };
const selectMonthStyle = { minWidth: 150 };

function Dashboard() {
    const formatYear = 'YYYY';
    const optionsPoint = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'end',
                offset: 4
            },

            title: {
                display: true,
                text: 'Performance employee chart by unit',
                padding: {
                    top: 10,
                    bottom: 10
                },
                font: {
                    size: 20,
                    style: 'normal',
                    family: 'Segoe UI'
                }
            }
        }
    };
    const optionsCoin = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                rtl: true
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'end',
                offset: 4
            },

            title: {
                display: true,
                text: 'Coin rate chart by unit',
                font: {
                    size: 20,
                    style: 'normal',
                    family: 'Segoe UI'
                }
            }
        }
    };
    const plugin = {
        beforeInit(chart) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
                // Call original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)();
                // Change the height as suggested in another answers
                this.width += 30;
            };
        }
    };
    const defChart = { labels: [], datasets: [] };
    const [yearPoint, setPointYear] = useState(dayjs().year());
    const [monthPoint, setPointMonth] = useState('');
    const [yearCoin, setCoinYear] = useState(dayjs().year());
    const { getTokenPromise, setLoading } = useContext(GetTokenV2Context);
    const { DepartmentID, IsFsu, CoinName } = useSelector((a) => a.DepartmentSettingSlice);
    const [pointDataChart, setPointDataChart] = useState(defChart);
    const [coinDataChart, setCoinDataChart] = useState(defChart);

    useEffect(() => {
        setLoading(true);
        getTokenPromise(getPointChart, DepartmentID, monthPoint, yearPoint).then((data) => {
            setLoading(false);
            let chartData = data;
            let opacity = 1;
            const colorArray = ['black', 'blue', 'green', 'red', 'yellow', 'purple']; // Add more colors as needed
            chartData.datasets.map((item, idx) => {
                item.backgroundColor = colorArray[idx % colorArray.length];
                return item;
            });

            setPointDataChart(chartData);
        });
    }, [yearPoint, monthPoint]);

    useEffect(() => {
        if (Boolean(IsFsu)) {
            setLoading(true);
            getTokenPromise(getCointChart, DepartmentID, yearCoin).then((data) => {
                setLoading(false);
                let chartData = data;
                chartData.datasets[0] = {
                    ...chartData.datasets[0],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 2
                };
                let countItem = chartData.datasets[0].data.length;
                const colorArray = ['black', 'blue', 'green', 'red', 'yellow', 'purple']; // Add more colors as needed

                chartData.datasets[0].data.forEach((_, idx) => {
                    chartData.datasets[0].backgroundColor.push(colorArray[idx % colorArray.length]);
                    chartData.datasets[0].borderColor.push('rgb(255,255,255)');
                });
                setCoinDataChart(chartData);
            });
        }
    }, [yearCoin]);

    const getMonths = (year) => {
        let months = [];
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        let minMonth = year === curYear ? curMonth : 12;

        months.push({ value: '', label: `All` });
        for (let index = 0; index < minMonth; index++) {
            months.push({ value: index + 1, label: `${index + 1}` });
        }

        return months;
    };

    const displayChartData = (isEmptyData, chartEl) =>
        isEmptyData ? (
            <Space style={spaceCenterStyle}>
                <Typography.Title level={5} style={{ color: '#ffbf00' }}>
                    No chart data available.
                </Typography.Title>
            </Space>
        ) : (
            chartEl
        );

    return (
        <div style={pdContainerStyle}>
            {!Boolean(IsFsu) && (
                <Card className="chart">
                    <Space style={{ width: '100%' }}>
                        <Typography.Text>Total coin in year:</Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            {formatNumber(pointDataChart.totalCoinInYear)} {CoinName}
                        </Typography.Title>
                    </Space>
                </Card>
            )}
            <Card className="chart chart-body">
                <Space style={{ ...spaceCenterStyle, ...mbStyle }}>
                    <Typography.Text style={{ marginBottom: 0 }}>Select year</Typography.Text>
                    <DatePicker
                        defaultValue={dayjs(dayjs(yearPoint.toString()), formatYear)}
                        format={formatYear}
                        picker="year"
                        onChange={(_, value) => setPointYear(value)}
                    />
                    <Typography.Text style={{ marginBottom: 0 }}>Select month</Typography.Text>
                    <Select
                        style={selectMonthStyle}
                        defaultValue={monthPoint}
                        options={getMonths(yearPoint)}
                        onChange={(value) => {
                            setPointMonth(value);
                        }}
                    />
                </Space>
                {displayChartData(
                    pointDataChart.labels.length === 0,
                    <Bar style={{ maxHeight: 650 }} options={optionsPoint} data={pointDataChart} />
                )}
            </Card>
            {Boolean(IsFsu) && (
                <Card className="chart chart-body">
                    <Space style={{ ...spaceCenterStyle, ...mbStyle }}>
                        <Typography.Text style={{ marginBottom: 0 }}>Select year</Typography.Text>
                        <DatePicker
                            defaultValue={dayjs(yearCoin.toString(), formatYear)}
                            format={formatYear}
                            picker="year"
                            onChange={(_, value) => setCoinYear(value)}
                        />
                    </Space>
                    <Space style={spaceCenterStyle}>
                        {displayChartData(
                            coinDataChart.labels.length === 0,
                            <Pie style={{ width: 600 }} plugins={[plugin]} options={optionsCoin} data={coinDataChart} />
                        )}
                    </Space>
                </Card>
            )}
        </div>
    );
}
export default Dashboard;
