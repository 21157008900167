import { ExclamationCircleFilled, MoreOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Dropdown, Grid, Image, Modal, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../../Hook/useAuth';
import useRefreshToken from '../../Hook/useRefreshToken';
import SearchInput from '../../components/SearchInput';
import AwardManualHistory from '../../components/User/AwardManualHistory';
import BadgeSchedule from '../../components/User/BadgeSchedule';
import CreateBadge from '../../components/User/CreateBadge';
import { PAGE_INDEX, PAGE_SIZE, PAGE_SIZE_OPTIONS_TABLE } from '../../constants/pagination';
import { TABLE } from '../../constants/table';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { Status, imgServer } from '../../dataConfig';
import { getAllBadgeWithPage, updateBadgeV2 } from '../../services/BadgeAPI';
import { getFileDownLoadBadgeHistory } from '../../services/ExportAPI';
import { setStatusLoading } from '../../store/LoadingSlice';
import AwardAutoHistory from '../../components/User/AwardAutoHistory';
import AwardHistory from '../../components/User/AwardHistory';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const { confirm } = Modal;
const BADGES_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
};
const BADGES_STATUS_CODE = {
    ACTIVE: 1,
    DEACTIVE: 2
};
const itemActions = [
    {
        key: 0,
        label: 'Edit'
    },
    {
        key: 1,
        label: 'Active',
        status: BADGES_STATUS_CODE.ACTIVE
    },
    {
        key: 2,
        label: 'Deactivate',
        status: BADGES_STATUS_CODE.DEACTIVE
    },
    {
        key: 4,
        label: 'Schedule',
        awardtype: 'manual'
    }
];
const statusFilterValues = [
    {
        text: BADGES_STATUS.ACTIVE,
        value: '1'
    },
    {
        text: BADGES_STATUS.INACTIVE,
        value: '2'
    }
];

function Badges() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(PAGE_INDEX);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [search, setSearch] = useState('');
    const [modalCreateBadge, setModalCreateBadge] = useState(false);
    const [modalAwardManual, setModalAwardManual] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [status, setStatus] = useState('');
    const [data, setData] = useState({});
    const { xs } = useBreakpoint();
    const { isHead } = useAuth();
    const [selectedBagdeId, setSelectedBadgeId] = useState(null);
    const { getToken, getTokenDownload } = useContext(GetTokenV2Context);

    const [badgeData, setRefresh] = useRefreshToken(getAllBadgeWithPage, page, pageSize, search, status);

    useEffect(() => {
        setData(badgeData);
        if (badgeData) {
            dispatch(setStatusLoading(false));
        }
    }, [badgeData]);

    const callbackSuccess = () => {
        setRefresh(new Date());
    };

    const handleDeactiveBagde = (badge) => {
        let body = {
            Status:
                badge.Status === BADGES_STATUS_CODE.ACTIVE ? BADGES_STATUS_CODE.DEACTIVE : BADGES_STATUS_CODE.ACTIVE,
            RuleID: badge.RuleDefintionID
        };

        getToken(updateBadgeV2, 'Update success', callbackSuccess, null, body, badge.ID);
    };

    const handleUpdateBagdeToAuto = (badge) => {
        let body = {
            AwardType: 'auto',
            RuleID: badge.RuleDefintionID
        };

        getToken(updateBadgeV2, 'Update success', callbackSuccess, null, body, badge.ID);
    };

    const showDeactiveConfirm = (badge) => {
        confirm({
            title: `Are you sure ${badge.Status === BADGES_STATUS_CODE.ACTIVE ? 'deactivate' : 'active'}  this medal?`,
            icon: <ExclamationCircleFilled />,
            content: null,
            centered: true,
            okType: 'danger',
            className: 'custom-confirm-styles',
            onOk() {
                handleDeactiveBagde(badge);
            }
        });
    };

    const showUpdateToAutoConfirm = (badge) => {
        confirm({
            title: `Are you sure update this medal to auto award?`,
            icon: <ExclamationCircleFilled />,
            width: '30rem',
            content: null,
            centered: true,
            okType: 'danger',
            className: 'custom-confirm-styles',
            onOk() {
                handleUpdateBagdeToAuto(badge);
            }
        });
    };

    const onClickDropDowmItem = (e, badge) => {
        switch (parseInt(e.key)) {
            case 0:
                setSelectedBadgeId(badge.ID);
                setModalCreateBadge(true);
                break;
            case 1:
            case 2:
                showDeactiveConfirm(badge);
                break;
            case 3:
                showUpdateToAutoConfirm(badge);
                break;
            case 4:
                setSelectedBadgeId(badge.ID);
                setShowScheduleModal(true);
                break;
            default:
                break;
        }
    };

    const getItemAction = (curStatus, awardType) => {
        return itemActions.filter((item) => item.status != curStatus).filter((item) => item.awardtype != awardType);
    };

    const actionColumRender = (_, record) => {
        return (
            <div>
                <Dropdown
                    menu={{
                        items: getItemAction(record.Status, record.AwardType),
                        onClick: (e) => onClickDropDowmItem(e, record)
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <MoreOutlined />
                </Dropdown>
            </div>
        );
    };

    const statusColumRender = (status) => (
        <Badge status={status === 1 ? 'success' : 'error'} text={Status.NormalStatus[status - 1]} />
    );

    const renderBadgesImg = (ImageURL, record, index) => (
        <Image height={70} key={index} width={70} src={`${imgServer}${record.ImageURL}`} preview={false} />
    );

    const columns = [
        {
            title: 'No.',
            dataIndex: 'ID',
            key: 'ID',
            width: 50,
            align: 'center',
            render: (id, record, index) => TABLE.COLUMN.RENDER_INDEX(id, record, index, page, pageSize)
        },
        {
            title: 'Medal',
            dataIndex: 'Badges',
            key: 'Badges',
            width: 100,
            align: 'center',
            render: renderBadgesImg
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description'
        },
        {
            title: 'Last time run',
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            width: '10%',
            align: 'center',
            render: (UpdatedDate) => moment(new Date(UpdatedDate)).format('DD/MM/YYYY HH:mm')
        },
        {
            title: 'Award type',
            dataIndex: 'AwardType',
            key: 'AwardType',
            width: 120,
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            width: 100,
            align: 'center',
            render: statusColumRender,
            filters: statusFilterValues,
            filterMultiple: false
        },
        {
            title: 'Action',
            dataIndex: 'ID',
            align: 'center',
            width: 50,
            hidden: !isHead,
            render: actionColumRender
        }
    ];

    const handleAfterChangeSearch = (value) => {
        setPage(PAGE_INDEX);
        setSearch(value);
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        setPage(pagination.current);
        setPageSize(pagination.pageSize);

        if (filters.Status) {
            setStatus(filters.Status[0]);
        } else {
            setStatus('');
        }
    };

    const handleAddBadge = () => {
        setModalCreateBadge(true);
    };

    return (
        <div style={{ margin: '1rem 2.5rem' }}>
            {/* Breadcrumb */}
            {/* <BreadcrumbApp/> */}

            {/* Filter */}
            <Row>
                <Col xs={24} md={12} style={{ marginBottom: '1rem' }}>
                    <Title style={{ lineHeight: 1.1, margin: 0 }} level={3}>
                        Medals List
                    </Title>
                    <Text className="sub-title">Medals that the department gives as rewards</Text>
                </Col>
                <Col
                    xs={24}
                    md={12}
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'end',
                        marginBottom: '1rem'
                    }}
                >
                    <Space>
                        <SearchInput afterChange={handleAfterChangeSearch} style={{ width: xs ? '100%' : 250 }} />
                        {isHead && (
                            <Space>
                                <Button type="primary" onClick={() => setModalAwardManual(true)}>
                                    Reward Medal
                                </Button>
                                <Button type="primary" onClick={handleAddBadge}>
                                    Add Medal
                                </Button>
                            </Space>
                        )}
                    </Space>
                </Col>
            </Row>

            {/* Table List */}
            <Table
                bordered
                style={{ borderRadius: 0 }}
                rowKey={(record) => record.ID}
                columns={columns.filter((col) => !col.hidden)}
                dataSource={data ? data.badges : []}
                onChange={onTableChange}
                pagination={{
                    total: data ? data.totalItems : 0,
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS_TABLE,
                    showTotal: (total) => `Total ${total} items`
                }}
            />
            {/* Modal Create or update Badge */}
            <Modal
                title={selectedBagdeId ? 'Update Medal' : 'Create Medal'}
                centered
                open={modalCreateBadge}
                footer={null}
                width={selectedBagdeId ? 800 : 500}
                onCancel={() => setModalCreateBadge(false)}
                afterClose={() => {
                    setSelectedBadgeId(null);
                    setModalCreateBadge(false);
                }}
                destroyOnClose
            >
                <CreateBadge bagdeId={selectedBagdeId} setRefresh={setRefresh} setModalState={setModalCreateBadge} />
            </Modal>
            {/* Modal Create or update Badge Schedule */}
            <Modal
                title="Schedule"
                centered
                open={showScheduleModal}
                footer={null}
                onCancel={() => setShowScheduleModal(false)}
                afterClose={() => {
                    setSelectedBadgeId(null);
                    setShowScheduleModal(false);
                }}
                destroyOnClose
            >
                <BadgeSchedule badgeId={selectedBagdeId} setRefresh={setRefresh} setModalState={setShowScheduleModal} />
            </Modal>
            {/* Modal Award Manual Badge */}
            <Modal
                zIndex={0}
                title="Reward Medal"
                centered
                open={modalAwardManual}
                footer={null}
                onCancel={() => setModalAwardManual(false)}
                afterClose={() => setModalAwardManual(false)}
                destroyOnClose
                width={xs ? '90%' : '60%'}
            >
                <AwardHistory />
            </Modal>
        </div>
    );
}

export default Badges;
