/**
 * Validate that the input value is not empty or only contains whitespace characters.
 *
 * @param {Object} rule - The validation rule object in antd.
 * @param {String} value - The value to validate.
 * @param {function} callback - Function, the callback function to trigger upon validation completion.
 */
export const notBeEmpty = (rule, value, callback) => {
    if (value && value.trim() === '') {
        callback(`${rule.field} must not be empty`);
    } else {
        callback();
    }
};

/**
 * Validate that the input value is not empty or only contains whitespace characters.
 * 
 * @param {Object} rule - The validation rule object in antd.
 * @param {String} value - The value to validate.
 * @param {function} callback - The callback function to trigger upon validation completion.
 */
export const validateVNPhone = (rule, value, callback) => {
    const regex = /^(\+?84|0)(3[2-9]|5[2689]|7[06789]|8[1-9]|9[0-9])(\d{7}|\d{8})$/;
    if (value && !regex.test(value)) {
        callback(`Invalid phone number`);
    } else {
        callback();
    }
};
