import { Checkbox, Col, Form, Input, InputNumber, Row, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import {
    checkConditionData,
    checkCaculateData,
    checkTimesData,
    checkCallendarEffortData
} from '../../utils/ValidateSyncRule';
import { QuestionCircleOutlined } from '@ant-design/icons';

function UpdateTemplateApi({ form, sampleData }) {
    const isMonthlyIncome = Form.useWatch('IsMonthlyIncome', form);

    const optionDay = useMemo(() => {
        const options = [];
        for (let i = 1; i <= 31; i++) {
            options.push({
                label: i.toString(),
                value: i.toString()
            });
        }
        return options;
    }, []);

    const optionHours = useMemo(() => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            options.push({
                label: i.toString(),
                value: i.toString()
            });
        }
        return options;
    }, []);

    const optionMinutes = useMemo(() => {
        const options = [];
        for (let i = 0; i < 60; i++) {
            options.push({
                label: i.toString(),
                value: i.toString()
            });
        }
        return options;
    }, []);

    const validateCondition = (_, value) => {
        let result = checkConditionData(value, sampleData);
        if (result.checkCondition) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.ConditionError));
    };
    const validateTimes = (_, value) => {
        let result = checkTimesData(value, sampleData);
        if (result.checkTimes) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.TimesError));
    };
    const validateCallendarEffort = (_, value) => {
        let result = checkCallendarEffortData(value, sampleData);
        if (result.checkTimes) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.TimesError));
    };
    const validateCaculationFormula = (_, value) => {
        let result = checkCaculateData(value, sampleData);
        if (result.checkCaculation) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(result.CaculationError));
    };

    return (
        <>
            <Row gutter={16} align="middle">
                <Col span={8}>
                    <Form.Item
                        name="AccountRow"
                        label={
                            <span>
                                Account Column &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="The column in the Excel file that you have selected is 'account'"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ required: true, message: 'Please input account column' }]}
                    >
                        <InputNumber style={{ width: '100%' }} type="text" min={1} placeholder="Enter account column" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="ProjectCodeRow" label="Project Code Column">
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter project code column" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="NoteRow" label="Note Column">
                        <InputNumber style={{ width: '100%' }} type="text" placeholder="Enter note column" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="TimeRun" label="Time Run" className="m-0">
                <Row gutter={16} align="middle">
                    <Col span={8}>
                        <Form.Item name="Day">
                            <Select
                                style={{ width: '100%' }}
                                filterOption={false}
                                notFoundContent={'Not Found'}
                                placeholder="Please choose day"
                                options={optionDay}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="Hours">
                            <Select
                                style={{ width: '100%' }}
                                filterOption={false}
                                notFoundContent={'Not Found'}
                                placeholder="Please choose hours"
                                options={optionHours}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="Minutes">
                            <Select
                                style={{ width: '100%' }}
                                filterOption={false}
                                notFoundContent={'Not Found'}
                                placeholder="Please choose minutes"
                                options={optionMinutes}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Row justify="start" gutter={16}>
                <Col span={12}>
                    <Form.Item name="Times" label="Times Column" rules={[{ validator: validateTimes }]}>
                        <Input
                            style={{ width: '100%' }}
                            type="text"
                            className="me-2"
                            placeholder="Enter times column"
                            min={1}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="Condition"
                        label={
                            <span>
                                Condition &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="Conditions for request points to be satisfied:&#10;column: [column number]&#10;equality '=='&#10;/inequality '!='&#10;can only compare columns with the same value type&#10;Example: [x]=='Approved'"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ validator: validateCondition }]}
                    >
                        <Input style={{ width: '100%' }} type="text" className="me-2" placeholder="Enter condition" />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="start" gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="CaculationFormula"
                        label={
                            <span>
                                Caculation Formula &nbsp;
                                <Tooltip
                                    arrow={{ pointAtCenter: true }}
                                    placement="topLeft"
                                    title="Scoring formula for request points:&#10;only numbers can be calculated with the Number and Percentage columns&#10;Example: [x]*5*[y]"
                                >
                                    <QuestionCircleOutlined style={{ fontSize: 16, color: '#FF0000' }} />
                                </Tooltip>
                            </span>
                        }
                        rules={[{ validator: validateCaculationFormula }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            type="text"
                            className="me-2"
                            placeholder="Enter caculation formula"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item valuePropName="checked" name="IsMonthlyIncome" style={{ marginBottom: 8 }}>
                        <Checkbox
                            onChange={form.setFieldsValue({ CalendarEffort: '' })}
                            checked={isMonthlyIncome}
                            id="checkbox"
                        >
                            Monthly Income
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            {isMonthlyIncome && (
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="CalendarEffort"
                            label={<span>Calendar Effort Column</span>}
                            rules={
                                isMonthlyIncome
                                    ? [
                                          { required: true, message: 'Calendar Effort Column is required' },
                                          { validator: validateCallendarEffort }
                                      ]
                                    : []
                            }
                        >
                            <Input
                                style={{ width: '100%' }}
                                type="text"
                                className="me-2"
                                placeholder="Enter calendar effort column"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Form.Item name="RawHeader" label="Caculation Formula" hidden={true}>
                <Input type="text" />
            </Form.Item>
            <Form.Item name="MappingData" label="Caculation Formula" hidden={true}>
                <Input type="text" />
            </Form.Item>
            <Form.Item name="RawSampleData" label="Caculation Formula" hidden={true}>
                <Input type="text" />
            </Form.Item>
        </>
    );
}

export default UpdateTemplateApi;
