import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import FooterContent from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { routes, routesGroup } from '../../constants/routes';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import './index.css';
import bgMainContent from '../../assets/images/bg_main.svg';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { logAuthEvent } from '../../services/Authentication';

const { Content, Footer } = Layout;
const footerStyle = {
    padding: '0.25rem 1.5rem',
    background: '#F5F5F5'
};
const contentStyle = {
    backgroundImage: `url(${bgMainContent})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 'calc(100vh - 46px - (30px + 1rem))',
    overflowX: 'hidden',
    overflowY: 'auto'
};
const contentErrorStyle = {
    backgroundImage: 'none',
    height: '100vh',
    overflow: 'auto'
};

const UserIndex = () => {
    const [routeList, setRouteList] = useState([]);
    const { isErrorPage } = useSelector((a) => a.LoadingSlice);
    const { IsFsu } = useSelector((a) => a.DepartmentSettingSlice);
    const { getToken } = useContext(GetTokenV2Context);
    const { account } = useSelector((state) => state.UserSlice);

    useEffect(() => {
        if (Boolean(Number(localStorage.getItem('isFirstLogin')))) {
            let body = {
                Account: account,
                TypeEvent: 1
            };
            getToken(logAuthEvent, null, null, null, body);
        }
        localStorage.setItem('isFirstLogin', '0');
    }, []);

    useEffect(() => {
        setRouteList(Boolean(IsFsu) ? routes : routesGroup);
    }, []);

    return (
        <Layout style={{ overflow: 'hidden' }}>
            {!isErrorPage && <NavBar />}
            <Content style={isErrorPage ? contentErrorStyle : contentStyle}>
                <Switch>
                    {routeList.map((data, i) => (
                        <Route key={i} exact path={data.path} component={data.component} />
                    ))}
                </Switch>
            </Content>
            {!isErrorPage && (
                <Footer style={footerStyle}>
                    <FooterContent />
                </Footer>
            )}
        </Layout>
    );
};

export default UserIndex;
