import React, { useEffect, useRef } from 'react';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppRouter from './AppRouter';
import ErrorPage from './pages/ErrorPage';
import Login from './pages/Login';
import Welcome from './pages/Welcome';
import CheckBrowserVersion from './components/CheckBrowserVersion';
import { checkVersion } from './utils/browser';
import { store } from './store/store';
import './css/style.css';
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import { useIdleTimer } from 'react-idle-timer';
import { GetTokenV2ContextProvider } from './context/GetTokenV2Context';
import { SocketContextProvider } from "./context/socketContext";

const antdThemeConfig = {
    token: {
        colorPrimary: '#735AD8',
        borderRadius: 16,
        colorBorder: '#D0D5DD',
        colorBorderSecondary: '#D0D5DD'
    }
};

const App = ({ instance }) => {
    const statusVersion = checkVersion();

    const handleOnIdle = (event) => {
        // console.log('user is idle', event)
        // console.log('last active', getLastActiveTime())
        instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    };

    const handleOnActive = (event) => {
        // console.log('user is active', event)
        // console.log('time remaining', getRemainingTime())
    };

    const handleOnAction = (event) => {
        // console.log('user did something', event)
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });

    return (
        <SocketContextProvider>
        <Provider store={store}>
            <ConfigProvider theme={antdThemeConfig}>
                <MsalProvider instance={instance}>
                    <UnauthenticatedTemplate>
                        {statusVersion ? <Login /> : <CheckBrowserVersion />}
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <GetTokenV2ContextProvider>
                            <BrowserRouter>
                                <Switch>
                                    <Route exact path="/" component={Welcome} />
                                    <Route
                                        exact
                                        path="/page-error-404-no-setting"
                                        component={() => (
                                            <ErrorPage
                                                title="The page you were looking for Not Have Setting Yet."
                                                desc="Department Not Have Setting Yet."
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/page-error-404"
                                        component={() => (
                                            <ErrorPage
                                                title="The page you were looking for is not found!"
                                                desc="You may have mistyped the address or the page may have moved."
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/page-error-503"
                                        component={() => (
                                            <ErrorPage
                                                title="Service Unavailable"
                                                desc="Sorry, we are under maintenance!"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/page-error-403"
                                        component={() => (
                                            <ErrorPage
                                                title="Forbidden Error!"
                                                desc="You do not have permission to view this resource."
                                            />
                                        )}
                                    />
                                    <Route path="/:depaName" component={AppRouter} />
                                </Switch>
                            </BrowserRouter>
                        </GetTokenV2ContextProvider>
                    </AuthenticatedTemplate>
                </MsalProvider>
            </ConfigProvider>
        </Provider>
         </SocketContextProvider>
    );
};

export default App;
