import { useContext, useEffect, useState } from 'react';
import { createProject, getProjectDetail, updateProject } from '../../services/ProjectAPI';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { getAllHead, getAllPM } from '../../services/UsermasterAPI';
import useRefreshToken from '../../Hook/useRefreshToken';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { PROJECT_STATUS } from '../../constants/status';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDispatch } from 'react-redux';
import { setStatusLoading } from '../../store/LoadingSlice';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const ranks = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' }
];
const types = [
    { value: 'Internal', label: 'Internal' },
    { value: 'External', label: 'External' }
];

function CreateProject({ projectId, setRefresh, setModalState }) {
    const dispatch = useDispatch();
    const { DepartmentID } = useSelector((state) => state.DepartmentSettingSlice);
    const [form] = Form.useForm();
    const { getToken, getTokenPromise } = useContext(GetTokenV2Context);
    const [PMDatas] = useRefreshToken(getAllPM);
    const [HeadDatas] = useRefreshToken(getAllHead);
    const [combinedArray, setCombinedArray] = useState([]);
    const [times, setTimes] = useState([dayjs(), dayjs().add(1, 'day')]);
    const initProject = {
        Key: '',
        Code: '',
        DepartmentID: DepartmentID,
        ManagerID: '',
        Type: types[0].value,
        Rank: ranks[0].value,
        Budget: 0,
        StartDate: new Date(),
        EndDate: null,
        Note: '',
        Status: 'On-going',
        CodeAvaiable: false
    };

    useEffect(() => {
        setCombinedArray([]);
        const PMManagers = PMDatas || [];
        const HeadManagers = HeadDatas || [];
        const Managers = PMManagers?.concat(HeadManagers);
        if (Managers) {
            setCombinedArray(Managers);
            dispatch(setStatusLoading(false));
        }
    }, [PMDatas, HeadDatas]);
    useEffect(() => {
        form.setFieldsValue(initProject);
        projectId &&
            getTokenPromise(getProjectDetail, DepartmentID, projectId).then((res) => {
                if (res) {
                    const startDate = res.project?.StartDate ? dayjs(res.project?.StartDate, dateFormat) : dayjs();
                    const endDate = res.project?.EndDate
                        ? dayjs(res.project?.EndDate, dateFormat)
                        : dayjs().add(1, 'day');
                    let project = {
                        ...res.project,
                        Times: [startDate, endDate]
                    };
                    form.setFieldsValue(project);
                    dispatch(setStatusLoading(false));
                }
            });
    }, [projectId]);

    const callbackSuccess = () => {
        setRefresh(new Date());
        setModalState(false);
    };

    const handleFinish = (values) => {
        const { Times } = values;
        const startDate = Times[0].format(dateFormat);
        const endDate = Times[1].format(dateFormat);
        const body = {
            ...values,
            StartDate: startDate,
            EndDate: endDate,
            DepartmentID: DepartmentID
        };
        delete body['Times'];
        
        getToken(
            projectId ? updateProject : createProject,
            projectId ? 'Project has been updated' : 'New project has been created',
            callbackSuccess,
            null,
            body,
            DepartmentID,
            projectId ?? projectId
        );
    };

    return (
        <>
            <Form form={form} layout="vertical" style={{ marginTop: '1.5rem' }} onFinish={debounce(handleFinish, 300)}>
                <Row gutter={40}>
                    <Col span={12}>
                        <Form.Item
                            label="Project Name"
                            name="Key"
                            rules={[{ required: true, message: 'Project Name is required', whitespace: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Rank" name="Rank" rules={[{ required: true, message: 'Rank is required' }]}>
                            <Select options={ranks} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Project Code"
                            name="Code"
                            rules={[{ required: true, message: 'Project Code is required', whitespace: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Project Manager"
                            name="ManagerID"
                            rules={[{ required: true, message: 'Project Manager is required' }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={combinedArray || []}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Type" name="Type" rules={[{ required: true, message: 'Type is required' }]}>
                            <Select options={types} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Budget"
                            name="Budget"
                            rules={[{ required: true, message: 'Budget is required' }]}
                        >
                            <InputNumber min={1} max={1000000000} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Times"
                            name="Times"
                            rules={[{ type: 'array', required: true, message: 'Please select time!' }]}
                        >
                            <RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Status"
                            name="Status"
                            rules={[{ required: true, message: 'Status is required' }]}
                        >
                            <Select
                                options={Object.keys(PROJECT_STATUS).map((key) => ({
                                    label: PROJECT_STATUS[key],
                                    value: PROJECT_STATUS[key]
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Note" name="Note">
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" align="middle">
                    <Button size="large" type="primary" htmlType="submit">
                        {projectId ? 'Update' : 'Create'}
                    </Button>
                </Row>
            </Form>
        </>
    );
}

export default CreateProject;
