import { Button, Card, Col, Form, Input, Row, Space, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import useAuth from '../../Hook/useAuth';
import useRefreshToken from '../../Hook/useRefreshToken';
import { GetTokenV2Context } from '../../context/GetTokenV2Context';
import { getSetting, saveSettingGroup, updSettingGroup } from '../../services/SettingAPI';
import { setStatusLoading } from '../../store/LoadingSlice';
import ErrorPage from '../ErrorPage';

const { Title } = Typography;
const SettingCard = styled(Card)`
    & {
        margin-bottom: 1.5rem;
    }
    &.ant-card .ant-card-head {
        padding: 1rem;
        min-height: auto;
    }
    &.ant-card .ant-card-body .ant-form-item {
        margin-bottom: 1rem;
    }
    &.ant-card .ant-card-body .ant-form-item.mb-0 {
        margin-bottom: 0;
    }
`;

function SettingGroup() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { getTokenFormData } = useContext(GetTokenV2Context);
    const { isDefaultHead } = useAuth();
    const { DepartmentID, Name, Code } = useSelector((a) => a.DepartmentSettingSlice);
    const [isNewSetting, setNewSetting] = useState(true);
    const [depaSetting, setRefreshDepaSetting] = useRefreshToken(getSetting, DepartmentID);
    let defaultSetting = {
        Name: Name,
        Code: Code
    };

    useEffect(() => {
        if (depaSetting && depaSetting !== 'NO DATA') {
            dispatch(setStatusLoading(false));
            setNewSetting(false);

            defaultSetting = {
                ...depaSetting,
                Name: depaSetting.Department.Name,
                Code: depaSetting.Department.Code
            };
            form.setFieldsValue(defaultSetting);
        } else {
            dispatch(setStatusLoading(false));
            setNewSetting(true);
            form.setFieldsValue(defaultSetting);
        }
    }, [depaSetting]);

    const handleResetData = () => {
        setRefreshDepaSetting(new Date());
    };

    const callbackSuccess = (res) => {
        const depaCode = res.Department.Code.split(' ').join('.');
        window.location.assign(`/${depaCode}/setting`);
        setRefreshDepaSetting(new Date());
    };

    const handleFinish = (values) => {
        const formData = new FormData();
        for (var key of Object.keys(values)) {
            formData.append(key, values[key]);
        }
        formData.append('DepartmentID', DepartmentID);
        formData.append('Logo', null);
        getTokenFormData(
            isNewSetting ? saveSettingGroup : updSettingGroup,
            'Setting has been updated',
            callbackSuccess,
            null,
            formData,
            DepartmentID
        );
    };

    const validateDepartmentCode = (_, value) => {
        if (value && value.includes('.')) {
            return Promise.reject('The Department cannot contain the "." character.');
        }
        return Promise.resolve();
    };

    return isDefaultHead ? (
        <>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                labelWrap
                labelAlign="left"
                style={{ margin: '1rem 2.5rem' }}
                onFinish={handleFinish}
            >
                <Title style={{ lineHeight: 1.1, marginBottom: '1rem' }} level={3}>
                    {isNewSetting ? 'Department' : depaSetting.Department?.Name} Setting
                </Title>

                <SettingCard title="Department Detail">
                    <Row gutter={[{ lg: 220, md: 100 }, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Department Name"
                                name="Name"
                                rules={[
                                    { required: true, message: 'Department Name is required' },
                                    { validator: validateDepartmentCode }
                                ]}
                            >
                                <Input type="text" min={3} max={250} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Department Code"
                                name="Code"
                                rules={[
                                    { required: true, message: 'Department Code is required' },
                                    { validator: validateDepartmentCode }
                                ]}
                            >
                                <Input type="text" min={3} max={250} />
                            </Form.Item>
                        </Col>
                    </Row>
                </SettingCard>
                <Row justify="end" align="middle">
                    <Space>
                        <Button size="large" htmlType="button" onClick={handleResetData}>
                            Reset
                        </Button>
                        <Button size="large" type="primary" htmlType="submit">
                            Save changes
                        </Button>
                    </Space>
                </Row>
            </Form>
        </>
    ) : (
        <ErrorPage
            title="The page you were looking for is not found!"
            desc="You may have mistyped the address or the page may have moved."
        />
    );
}

export default SettingGroup;
